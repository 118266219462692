<template>
  <div class="memberAudit" style=" margin: -15px -15px;">
    <a-spin :spinning="spinning">
    <a-collapse v-model="activeKey">
      <a-collapse-panel key="1" header="本周会议总览" style="background: #fff;border-bottom: 10px solid #F0F2F5">
        <a-row >
          <a-col class="box-card" :span="6" v-for="list in thisWeekList" :key="list.id">
            <div class="card">
              <div class="card-img">
                <div class="card-icon">
                  <div v-if="list.domainCode === 'yunicu'" class="quSty">
                    云ICU
                  </div>
                  <div v-if="list.domainCode === 'ie-learning'" class="quStyI">
                    IELearning
                  </div>
                  <div v-if="list.domainCode === 'yichimeeting'" class="quStyY">
                    医驰
                  </div>
                  <div v-if="list.domainCode === 'aphouse'" class="quStyA">
                    APnet
                  </div>
                  <div v-if="list.type === 'SINGLE'" class="quStyXH" >
                    普通小会
                  </div>
                  <div v-if="list.type === 'SERIES'" class="quStyX">
                    系列会
                  </div>
                  <div v-if="list.type === 'CONVENTION'" class="quStyD">
                    多会场
                  </div>
                </div>
                <img class="img" :src="list.coverUrl"/>
                <div class="card-top">负责人：{{list.leaderSales}} {{list.leaderOperate}}</div>
              </div>
              <div class="title">
                <div class="title-t">{{list.title}}</div>
                <div class="title-liveTime">直播时间：{{list.showTime}}</div>
              </div>
<!--              <div class="bottom">-->
<!--                <div>总结</div>-->
<!--                <div style="color: #e3e3e3">|</div>-->
<!--                <div>统计</div>-->
<!--              </div>-->
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>

      <a-collapse-panel key="2" header="上周会议回顾" style="background: #fff;border-bottom: 10px solid #F0F2F5">
        <a-row >
          <a-col class="box-card" :span="6" v-for="list in lastWeekList" :key="list.id">
            <div class="card">
              <div class="card-img">
                <div class="card-icon">
                  <div v-if="list.domainCode === 'yunicu'" class="quSty">
                    云ICU
                  </div>
                  <div v-if="list.domainCode === 'ie-learning'" class="quStyI">
                    IELearning
                  </div>
                  <div v-if="list.domainCode === 'yichimeeting'" class="quStyY">
                    医驰
                  </div>
                  <div v-if="list.domainCode === 'aphouse'" class="quStyA">
                    APnet
                  </div>
                  <div v-if="list.type === 'SINGLE'" class="quStyXH" >
                    普通小会
                  </div>
                  <div v-if="list.type === 'SERIES'" class="quStyX">
                    系列会
                  </div>
                  <div v-if="list.type === 'CONVENTION'" class="quStyD">
                    多会场
                  </div>
                </div>
                <img class="img" :src="list.coverUrl"/>
                <div class="card-top">负责人：{{list.leaderSales}} {{list.leaderOperate}}</div>
              </div>
              <div class="title">
                <div class="title-t">{{list.title}}</div>
                <div class="title-liveTime">直播时间：{{list.showTime}}</div>
              </div>
<!--              <div class="bottom">-->
<!--                <div>总结</div>-->
<!--                <div style="color: #e3e3e3">|</div>-->
<!--                <div>统计</div>-->
<!--              </div>-->
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>

      <a-collapse-panel key="3" header="下周会议计划" style="background: #fff;border-bottom: 10px solid #F0F2F5">
        <a-row >
          <a-col class="box-card" :span="6" v-for="list in nextWeekList" :key="list.id">
            <div class="card">
              <div class="card-img">
                <div class="card-icon">
                  <div v-if="list.domainCode === 'yunicu'" class="quSty">
                    云ICU
                  </div>
                  <div v-if="list.domainCode === 'ie-learning'" class="quStyI">
                    IELearning
                  </div>
                  <div v-if="list.domainCode === 'yichimeeting'" class="quStyY">
                    医驰
                  </div>
                  <div v-if="list.domainCode === 'aphouse'" class="quStyA">
                    APnet
                  </div>
                  <div v-if="list.type === 'SINGLE'" class="quStyXH" >
                    普通小会
                  </div>
                  <div v-if="list.type === 'SERIES'" class="quStyX">
                    系列会
                  </div>
                  <div v-if="list.type === 'CONVENTION'" class="quStyD">
                    多会场
                  </div>
                </div>
                <img class="img" :src="list.coverUrl"/>
                <div class="card-top">负责人：{{list.leaderSales}} {{list.leaderOperate}}</div>
              </div>
              <div class="title">
                <div class="title-t">{{list.title}}</div>
                <div class="title-liveTime">直播时间：{{list.showTime}}</div>
              </div>
<!--              <div class="bottom">-->
<!--                <div>总结</div>-->
<!--                <div style="color: #e3e3e3">|</div>-->
<!--                <div>统计</div>-->
<!--              </div>-->
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>

      <a-collapse-panel key="4" header="其他未开始会议" style="background: #fff;border-bottom: 10px solid #F0F2F5">
        <a-row >
          <a-col class="box-card" :span="6" v-for="list in elseWeekList" :key="list.id">
            <div class="card">
              <div class="card-img">
                <div class="card-icon">
                  <div v-if="list.domainCode === 'yunicu'" class="quSty">
                    云ICU
                  </div>
                  <div v-if="list.domainCode === 'ie-learning'" class="quStyI">
                    IELearning
                  </div>
                  <div v-if="list.domainCode === 'yichimeeting'" class="quStyY">
                    医驰
                  </div>
                  <div v-if="list.domainCode === 'aphouse'" class="quStyA">
                    APnet
                  </div>
                  <div v-if="list.type === 'SINGLE'" class="quStyXH" >
                    普通小会
                  </div>
                  <div v-if="list.type === 'SERIES'" class="quStyX">
                    系列会
                  </div>
                  <div v-if="list.type === 'CONVENTION'" class="quStyD">
                    多会场
                  </div>
                </div>
                <img class="img" :src="list.coverUrl"/>
                <div class="card-top">负责人：{{list.leaderSales}} {{list.leaderOperate}}</div>
              </div>
              <div class="title">
                <div class="title-t">{{list.title}}</div>
                <div class="title-liveTime">直播时间：{{list.showTime}}</div>
              </div>
<!--              <div class="bottom">-->
<!--                <div>总结</div>-->
<!--                <div style="color: #e3e3e3">|</div>-->
<!--                <div>统计</div>-->
<!--              </div>-->
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>
    </a-spin>
  </div>
</template>

<script>
import {getLiveStreamOverview} from "@/service/MedicalConference_y";

export default {
  data(){
    return{
      spinning:false,
      activeKey: ['1'],
      thisWeekList:[],
      lastWeekList:[],
      nextWeekList:[],
      elseWeekList:[],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"直播会议总览")
  },
  watch: {
    activeKey(key) {
      console.log(key);
    },
  },
  mounted() {
    this.getList1()
    this.getList2()
    this.getList3()
    this.getList4()
  },
  methods:{
    async getList1() {
      this.spinning = true
      const response = await getLiveStreamOverview('本周')
      if(response.code === 0){
        this.thisWeekList = response.data
      }
      this.spinning = false
    },
    async getList2() {
      this.spinning = true
      const response = await getLiveStreamOverview('上周')
      if(response.code === 0){
        this.lastWeekList = response.data
      }
      this.spinning = false
    },
    async getList3() {
      this.spinning = true
      const response = await getLiveStreamOverview('下周')
      if(response.code === 0){
        this.nextWeekList = response.data
      }
      this.spinning = false
    },
    async getList4() {
      this.spinning = true
      const response = await getLiveStreamOverview('其他')
      if(response.code === 0){
        this.elseWeekList = response.data
      }
      this.spinning = false
    },
  }
}
</script>
<style lang="scss">
.memberAudit{
  min-height: 110%;
  background-color: #F0F2F5;
  .ant-collapse{
    border: none;
  }
  .box-card{
    height: 220px;
    margin-bottom: 10px;
    .card{
      border:1px solid #f5f5f5;
      width: 98%;
      height: 100%;
      margin: auto;
      .card-img{
        width: 100%;
        position: relative;
        .card-icon{
          display: flex;
          position: absolute;
          top: 0;
          padding: 10px;
          .quSty{
            width: 50px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #91D5FF;
            background: #E6F7FF;
            color: #1890FF;
            text-align: center;
            border-radius: 5px;

          }.quStyI{
             width: 100px;
             height: 30px;
             line-height: 30px;
             border: 1px solid #D3ADF7;
             background: #F9F0FF;
             color: #722ED1;
             text-align: center;
             border-radius: 5px;
           }.quStyY{
              width: 50px;
              height: 30px;
              line-height: 30px;
              border: 1px solid #FFA39E;
              background: #FEF0EF;
              color: #F6222D;
              text-align: center;
              border-radius: 5px;
            }.quStyA{
               width: 50px;
               height: 30px;
               line-height: 30px;
               border: 1px solid #87E8DE;
               background: #E6FFFB;
               color: #13C2C2;
               text-align: center;
               border-radius: 5px;
             }.quStyX{
                width: 70px;
                height: 30px;
                line-height: 30px;
                border: 1px solid #91e887;
                background: #eff5ee;
                color: #35c924;
                text-align: center;
                border-radius: 5px;
                margin-left: 10px;
              }.quStyXH{
                 width: 70px;
                 height: 30px;
                 line-height: 30px;
                 border: 1px solid #444444;
                 background: #e4e5e5;
                 color: #444444;
                 text-align: center;
                 border-radius: 5px;
                 margin-left: 10px;
               }.quStyD{
                  width: 70px;
                  height: 30px;
                  line-height: 30px;
                  border: 1px solid #FFBB96;
                  background: #FFF2E8;
                  color: #FFBB96;
                  text-align: center;
                  border-radius: 5px;
                  margin-left: 10px;
                }
        }
        .card-top{
          height: 30px;
          line-height: 30px;
          color: #fff;
          padding-left: 10px;
          width: 100%;
          background: rgba(0,0,0,0.5);
          position: absolute;
          bottom: 0;
        }
        .img{
          width: 100%;
          height: 150px;
          border:1px solid #f5f5f5;
        }
      }
      .title{
        height: 70px;color: #333333;padding: 10px;
        .title-t{
          font-size: 14px;
          font-weight: bold;
          width: 100%;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          word-break: break-all;
        }
        .title-liveTime{
          font-size: 12px;color: #8c8a8a;
        }
      }
      .bottom{
        font-size: 12px;
        background: #f5f5f5;
        height: 50px;
        line-height: 50px;
        color: #8c8a8a;
        display: flex;
        justify-content: space-around;
        cursor: pointer;
      }
    }
  }
}
</style>